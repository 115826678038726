<template>
  <!-- TODO: Pour faire l'effet d'image qui follow sur le hover -->
  <!-- https://codepen.io/coreDeiv/pen/YzqzRKK -->
  <footer
    id="footer"
    class="bottom-0 bg-black text-white w-full h-auto pb-14"
    data-footer-element
  >
    <div class="flex flex-row px-[10%] py-[5%]" data-footer-element>
      <div class="flex-1 mb-24 md:mb-0" data-footer-element>
        <div class="mb-3 md:mb-8 w-fit" data-footer-element>
          <ImageFooterOnHover
            data-footer-element
            class="onHover w-fit"
            imageSrc="/footer-questions-small.png"
          >
            <template #title>
              <span
                class="font-bold text-sm md:text-lg z-10 relative bg-black mix-blend-difference text-white"
                data-footer-element
                >QUESTION &</span
              >
              <br />
              <span
                class="font-bold text-sm md:text-lg z-10 relative leading-3 bg-black mix-blend-difference text-white"
                data-footer-element
                >NEW BUISINESS</span
              >
            </template>
            <template #content>
              <span
                class="font-extralight z-10 relative bg-black mix-blend-difference text-white"
                data-footer-element
              >
                contact@lmlccommunication.fr
              </span>
            </template>
          </ImageFooterOnHover>
        </div>

        <div class="mb-3 md:mb-8 w-fit" data-footer-element>
          <ImageFooterOnHover
            data-footer-element
            class="onHover w-fit"
            imageSrc="/footer-job-small.png"
          >
            <template #title>
              <span
                class="font-bold text-sm md:text-lg z-10 relative leading-3 bg-black mix-blend-difference text-white"
                data-footer-element
                >JOB</span
              >
            </template>
            <template #content>
              <span
                class="font-extralight z-10 relative bg-black mix-blend-difference text-white"
                data-footer-element
              >
                talents@lmlccommunication.fr
              </span>
            </template>
          </ImageFooterOnHover>
        </div>

        <div class="mb-3 md:mb-8 w-fit" data-footer-element>
          <ImageFooterOnHover
            data-footer-element
            class="onHover w-fit"
            imageSrc="/footer-tuned-small.png"
          >
            <template #title>
              <span
                class="font-bold text-sm md:text-lg z-10 relative leading-3 bg-black mix-blend-difference text-white"
                data-footer-element
                >NEWSLETTER</span
              >
              <div class="flex flex-col gap-8">
                <div class="flex flex-row items-center w-[300px]">
                  <input
                    type="email"
                    v-model="email"
                    placeholder="mail"
                    class="placeholder:italic placeholder:text-gray-400 block bg-white w-full border border-gray-300 shadow-sm focus:outline-none text-gray-700 py-2 px-4 leading-tight focus:ring-1 cursor-none"
                    v-cursorAnimation
                  />
                  <button
                    name="Button send email to newsletter"
                    id="sendEmail"
                    @click="sendDataToMake"
                    class="border-0 text-white rounded-full p-2 ml-2 active:bg-rose-neon/50 cursor-none"
                    v-cursorAnimation
                  >
                    <!--   -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </button>
                </div>

                <!-- <span id="axeptionConsent" @click="getClick"></span> -->
              </div>
            </template>
          </ImageFooterOnHover>
          <!-- opacity: 0; width: 100%; height: auto; max-width: 400px; box-sizing:
          border-box; transition: ease .2s; position: absolute; transform:
          translate(-50%, -50%); z-index: -1; object-fit: cover; -->
          <!-- TODO: Faire un input pour récupérer email en contact -->
        </div>
        <div class="flex flex-row mb-2">
          <!-- TODO mettre le noref no... -->
          <a
            href="https://www.instagram.com/lmlccommunication/"
            class="ml-[-2px] mr-1 hover:cursor-none"
            target="_blank"
            referrerpolicy="no-referrer|no-referrer-when-downgrade"
          >
            <IconsInstagram
              alt="(Ouvre le l'instagram de LMLC COMMUNICATION dans un nouvel onglet)"
              class="w-6 h-auto text-white"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/lmlccommunication/?originalSubdomain=fr"
            class="ml-[-2px] mr-1 hover:cursor-none"
            target="_blank"
            referrerpolicy="no-referrer|no-referrer-when-downgrade"
          >
            <IconsLinkedin
              alt="(Ouvre le linkedin de LMLC COMMUNICATION dans un nouvel onglet)"
              class="w-6 h-auto text-white"
            />
          </a>
        </div>
        <div class="z-10 relative" data-footer-element>PARIS, FR</div>
      </div>
    </div>
    <div class="relative w-full" data-footer-element>
      <div
        data-footer-element
        class="absolute flex flex-col justify-content items-center bottom-0 w-full"
      >
        <div class="flex flex-row">
          <NuxtLink
            data-footer-element
            to="/mentions-legales"
            class="font-monteserrat font-extralight text-sm mt-4 mb-6 cursor-none"
            v-cursorAnimation
            >mentions légales</NuxtLink
          >
          <span class="mt-4 mb-4 mx-4">-</span>
          <a
            data-footer-element
            class="font-monteserrat font-extralight text-sm mt-4 mb-6 cursor-none"
            v-cursorAnimation
            >gestion des cookies</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import instagram from "assets/icons/instagram.svg";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      email: "",
      axeptioScreen: null,
      acceptSendEmailButton: null,
      rejectSendEmailButton: null,
    };
  },
  methods: {
    // getClick() {
    //   let axeptionButtons = document.querySelectorAll("#axeptio_btn_undefined");

    //   if (axeptionButtons.length > 1) {
    //     axeptionButtons[0].addEventListener("click", () => {
    //       null
    //     });
    //     axeptionButtons[1].addEventListener("click", () => {
    //       this.sendDataToMake();
    //     });
    //   } else {
    //     this.axeptioScreen[0].style.display = "none";
    //   }
    // },
    // handleSendEmail() {
    //   this.axeptioScreen = document.getElementsByClassName("Widget__WidgetStyle-sc-zhn46e-2 jyqhwN axeptio_widget ax-widget");
    //   if (this.axeptioScreen.length === 0) {
    //     this.openAxeptioEmailConsent();
    //     this.getClick();
    //   } else {
    //     this.axeptioScreen[0].style.display = "block";
    //     this.getClick();
    //   }
    // },
    // openAxeptioEmailConsent() {
    //   void 0 === window._axcb && (window._axcb = []);
    //   window._axcb.push(function (axeptio) {
    //     axeptio.mountWidget({
    //       service: "processings",
    //       name: "LMLC Newsletter",
    //       node: document.getElementById("axeptionConsent"),
    //     });
    //   });
    // },
    // TODO : Retirer tout inté de Axeptio
    async sendDataToMake() {
      const webhookUrl = "https://hook.eu2.make.com/mbfn5v1e0b73rr4r0buwalq0nhe4ryl7"; // Remplacez avec l'URL de votre webhook Make.com

      const { data, pending, error } = await useFetch(webhookUrl, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: {
          email: this.email,
        },
      });
    },
  },
  created() {},
};
</script>

<style scoped>
.onHover:hover {
  transition: ease 0.2s all;
  color: black;
}
.email-text {
  z-index: 100; /* ou une autre valeur suffisamment élevée */
}
</style>
